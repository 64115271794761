import axios from "axios";
import {laravel_api} from "../variables";

export const apiPhotoChangeStatus = ({
  token,
  photoId,
  status
}) => {

  return new Promise((resolve, reject) => {

    axios.post(`${laravel_api}/admin/photo.change_status`, {
      token,
      photo_id: photoId,
      status
    }, {
      headers: {
        Accept: 'application/json',
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}
