import React, {Component, Fragment} from 'react';
import axios from "axios";
import {laravel_api} from "../variables";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import moment from 'moment';
import {connect} from "react-redux";

class PopupEditModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            id: props.id,
            loading: false,
            title: '',
            message: '',
            active: 0,
            type: 'once',
            instagram: '',
            facebook: '',
            tiktok: '',
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            open: props.open,
            id: props.id,
        }, () => {
            if (this.state.id !== null) {
                this.loadData();
            }
        })
    }

    changeField(type, event) {

        this.setState({
            [type]: event.target.value
        })

    }

    loadData() {

        let react = this;
        react.setState({
            loading: true
        })

        axios.get(laravel_api + '/admin/popup.get?token=' + react.props.token + "&id=" + react.state.id).then(function (response) {
            console.log(response);
            react.setState({
                loading: false,
                title: response.data.popup.title,
                message: response.data.popup.message,
                active: response.data.popup.active,
                type: response.data.popup.type,
                instagram: response.data.popup.instagram,
                facebook: response.data.popup.facebook,
                tiktok: response.data.popup.tiktok
            })
        }).catch(function (error) {
            console.log(error);
        });

    }

    submit() {

        let react = this;
        react.setState({
            loading: true
        })

        axios.post(laravel_api + '/admin/popup.edit', {
            token: react.props.token,
            id: react.state.id,
            title: react.state.title,
            message: react.state.message,
            active: react.state.active,
            type: react.state.type,
            instagram: react.state.instagram,
            facebook: react.state.facebook,
            tiktok: react.state.tiktok
        }).then(function (response) {
            console.log(response);
            react.props.onUpdate();
        }).catch(function (error) {
            console.log(error);
        });

    }

    render() {

        let react = this;

        if (this.state.open) {

            let content = (
                <div className={"modal-spinner"}>
                    <div className="v2-spinner"/>
                </div>
            );

            if (!this.state.loading) {

                content = (
                    <Fragment>
                        <div className="modal-top">
                            <span className="modal-title">Edit popup</span>
                            <button className="modal-close" onClick={this.props.onClose.bind(this)}>×</button>
                        </div>
                        <div className="modal-content">
                            <div className="modal-form-element">
                                <span className="modal-form-element-label">Title</span>
                                <input type="text" value={react.state.title} onChange={react.changeField.bind(react, 'title')}/>
                            </div>
                            <div className="modal-form-element">
                                <span className="modal-form-element-label">Message</span>
                                <textarea cols={20} value={react.state.message} onChange={react.changeField.bind(react, 'message')}/>
                            </div>
                            <div className="modal-form-element">
                                <span className="modal-form-element-label">Type</span>
                                <select value={react.state.type} onChange={react.changeField.bind(react, 'type')}>
                                    <option value="once">Once</option>
                                    <option value="entering">All time</option>
                                </select>
                            </div>
                            <div className="modal-form-element">
                                <span className="modal-form-element-label">Active</span>
                                <select value={react.state.active} onChange={react.changeField.bind(react, 'active')}>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                            <div className="modal-form-element">
                                <span className="modal-form-element-label">Facebook</span>
                                <input placeholder={"https://www.facebook.com/zuck"} type="text" value={react.state.facebook} onChange={react.changeField.bind(react, 'facebook')}/>
                            </div>
                            <div className="modal-form-element">
                                <span className="modal-form-element-label">Instagram</span>
                                <input placeholder={"https://www.instagram.com/muslapp/"} type="text" value={react.state.instagram} onChange={react.changeField.bind(react, 'instagram')}/>
                            </div>
                            <div className="modal-form-element">
                                <span className="modal-form-element-label">Tiktok</span>
                                <input placeholder={"https://www.tiktok.com/@0udanovskiy2"} type="text" value={react.state.tiktok} onChange={react.changeField.bind(react, 'tiktok')}/>
                            </div>
                            <div className="modal-form-element row">
                                <button className="modal-form-submit" onClick={react.submit.bind(react)}>Save</button>
                            </div>
                        </div>
                    </Fragment>
                );
            }

            return (
                <Fragment>
                    <div className="modal-fade" onClick={this.props.onClose.bind(this)}/>
                    <div className="modal">
                        {content}
                    </div>
                </Fragment>
            )

        }

        return <div/>

    }

}

export default connect(
    state => ({
        auth: state.user.auth,
        token: state.user.token,
    })
)(PopupEditModal);
