import React, { Component, Fragment } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { connect } from 'react-redux';
import { Redirect } from "react-router";
import axios from "axios";
import { laravel_api } from "../variables";
import { DelayInput } from 'react-delay-input';
import ProfileModal from "../modals/ProfileModal";
import Chat from "../components/Chat";
import { get_image_url } from "../utils";
import DatePicker from 'react-datepicker';
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

class Profiles extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			more_loading: false,
			profiles: [],
			search: '',
			type: '',
			modal_profile_open: false,
			modal_profile_id: null,
			selected_profile_id: null,
			offset: 0,
			hey: props.hey,
			min_date: null,
			max_date: null
		}
	}

	componentWillMount() {
		this.loadProfiles();
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			hey: nextProps.hey
		}, () => {
			this.setState({
				loading: true,
				more_loading: false,
				profiles: [],
				search: '',
				type: '',
				modal_profile_open: false,
				modal_profile_id: null,
				selected_profile_id: null,
				offset: 0,
			}, () => {
				this.loadProfiles(true, false);
			})
		})
	}

	loadMore() {

		let react = this;
		let offset = react.state.offset + 100;

		react.setState({
			offset
		}, () => {
			react.loadProfiles(false, true);
		})

	}

	loadProfiles(loading = true, more_loading = false) {

		let react = this;
		react.setState({
			loading,
			more_loading
		});

		let min_date = "";
		let max_date = "";

		if (react.state.min_date !== null) {
			min_date = "&min_date=" + moment(react.state.min_date).format("YYYY-MM-DD 00:00");
		}

		if (react.state.min_date !== null) {
			max_date = "&max_date=" + moment(react.state.max_date).format("YYYY-MM-DD 23:59");
		}

		axios.get(laravel_api + '/admin/profiles.get?token=' + react.props.token + "&type=" + react.state.type + "&search=" + react.state.search + "&offset=" + react.state.offset + "&hey=" + react.state.hey + min_date + max_date).then(function (response) {

			console.log(response);

			let profiles = react.state.profiles.concat(response.data.profiles);
			if (!more_loading) {
				profiles = response.data.profiles;
			}

			react.setState({
				loading: false,
				more_loading: false,
				profiles
			})

		}).catch(function (error) {
			console.log(error);
		});

	}

	getProfileType(type) {

		if (type === 'fun') {
			return "FUN";
		}

		if (type === 'flirt') {
			return "FLIRT";
		}

		if (type === 'friend') {
			return "FRIEND";
		}

	}

	changeType(event) {
		this.setState({
			type: event.target.value,
			offset: 0
		}, () => {
			this.loadProfiles();
		})
	}

	changeSearch(event) {
		this.setState({
			search: event.target.value,
			offset: 0
		}, () => {
			this.loadProfiles();
		})
	}

	openProfileModal(profile_id) {
		this.setState({
			modal_profile_open: true,
			modal_profile_id: profile_id,
		})
	}

	openChatModal(profile_id) {
		this.setState({
			selected_profile_id: profile_id
		})
	}

	closeProfileModal() {
		this.setState({
			modal_profile_open: false,
			modal_profile_id: null,
		})
	}

	delete(id) {

		if (window.confirm("Are you sure you want to delete all the profiles of this user and his account forever?")) {

			let react = this;
			react.setState({
				loading: true,
			});

			axios.get(
				laravel_api
				+ '/admin/profile.delete?token='
				+ react.props.token
				+ "&profile_id="
				+ id).then(function () {
					react.loadProfiles();
				}).catch(function (error) {
					console.log(error);
				});

		}

	}

	getChatWindow() {

		if (this.state.selected_profile_id !== null) {
			return (
				<Chat
					profile_id={this.state.selected_profile_id}
					onClose={() => {
						this.setState({
							selected_profile_id: null
						})
					}}
				/>
			)
		}

	}

	checkNeedReview(profile) {
		if (profile.photos.filter((photo) => { return photo.status === 1 }).length > 0) {
			return 'Yes'
		}

		return 'No'
	}

	render() {

		let react = this;
		if (!this.props.auth) {
			return (<Redirect to={"/login"} />)
		}

		let content = (<div className="v2-spinner-inner"><div className="v2-spinner" /></div>);

		if (!this.state.loading) {
			content = (
				<div className="content-area">
					<div className="bordered-table">
						<table>
							<tr>
								<th style={{ width: '30px' }} />
								<th>Nickname</th>
								<th>Email or Phone</th>
								<th>Type</th>
								<th>Need review</th>
								<th>Created at</th>
								<th className="padding-no" style={{ width: '40px' }} />
								<th className="padding-no" style={{ width: '40px' }} />
								<th className="padding-no" style={{ width: '40px' }} />
							</tr>
							{
								react.state.profiles.map(function (profile) {
									return (
										<tr>
											<td><img className="bordered-table-avatar" src={get_image_url(profile.avatar, 20, 20)} /></td>
											<td>{profile.name}</td>
											<td>
												{profile.user.email != null && profile.user.email}
												{profile.user.phone != null && profile.user.phone}
											</td>
											<td>{react.getProfileType(profile.type)}</td>
											<td>{react.checkNeedReview(profile)}</td>
											<td>{moment(profile.created_at).format("DD.MM.YYYY kk:mm")}</td>
											<td className="padding-no" onClick={react.openChatModal.bind(react, profile.id)}>
												<button className="send-button">
													{profile.support === 1 &&
														<span className="send-button-circle" />
													}
													<i className="fa fa-send" />
												</button>
											</td>
											<td className="padding-no" onClick={react.openProfileModal.bind(react, profile.id)}>
												<button className="edit-button"><i className="fa fa-eye" /></button>
											</td>
											<td className="padding-no" onClick={react.delete.bind(react, profile.id)}>
												<button className="delete-button"><i className="fa fa-trash" /></button>
											</td>
										</tr>
									)
								})
							}
						</table>
					</div>
					{!react.state.more_loading && <button className={"load-more-button"} onClick={react.loadMore.bind(react)}>Load more</button>}
				</div>
			)
		}

		return (
			<Fragment>
				<Header menu={true} />
				{this.getChatWindow()}
				<div className="content">
					<div className="content-inner">
						<ProfileModal
							token={react.props.token}
							open={react.state.modal_profile_open}
							profile_id={react.state.modal_profile_id}
							onClose={this.closeProfileModal.bind(this)}
						/>
						<div className="page-top row"><h1 className="page-title">Profiles</h1>
							<div className="select-field" style={{ marginRight: 15 }}>
								<div className="select-field-top">
									<DelayInput
										minLength={2}
										placeholder="Search"
										type="text"
										value={this.state.search}
										delayTimeout={300}
										onChange={this.changeSearch.bind(this)}
									/>
									<i className="fa fa-search" />
								</div>
							</div>
							<select className="select-input" value={this.state.type} onChange={this.changeType.bind(this)}>
								<option value="">All profiles</option>
								<option value="fun">FUN</option>
								<option value="flirt">FLIRT</option>
								<option value="friend">FRIEND</option>
								<option value="review">NEED PHOTO REVIEW</option>
							</select>
							<div className="date-input row">
								<DatePicker
									selected={react.state.min_date}
									dateFormat="dd.MM.yyyy"
									onChange={(date) => {
										react.setState({
											min_date: date
										}, () => {
											this.loadProfiles();
										})
									}}
								/>
								<span className="date-input-label row">-</span>
								<DatePicker
									selected={react.state.max_date}
									dateFormat="dd.MM.yyyy"
									onChange={(date) => {
										react.setState({
											max_date: date
										}, () => {
											this.loadProfiles();
										})
									}}
								/>
							</div>
						</div>
						{content}
					</div>
				</div>
				<Footer />
			</Fragment>
		)
	}

}


export default connect(
	state => ({
		auth: state.user.auth,
		token: state.user.token,
		hey: state.user.hey
	})
)(Profiles);
