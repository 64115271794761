import React, { Component, Fragment } from 'react';
import axios from "axios";
import { laravel_api } from "../variables";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import moment from 'moment';
import { get_image_url } from "../utils";
import { apiPhotoChangeStatus } from "../actions/photoActions";
import { apiProfileChangeStatus } from "../actions/profileActions";

export default class ProfileModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			open: props.open,
			profile_id: props.profile_id,
			token: props.token,
			profile: null,
			loading: true,
			lightbox_open: false,
			lightbox_link: null,
		}
	}

	componentWillReceiveProps(props) {
		this.setState({
			profile_id: props.profile_id,
			token: props.token,
			open: props.open
		}, () => {
			if (this.state.profile_id !== null) {
				this.loadPatient();
			}
		})
	}

	loadPatient() {

		let react = this;
		react.setState({
			loading: true,
		});

		axios.get(laravel_api + '/admin/profile.get?token=' + react.props.token + "&id=" + react.state.profile_id).then(function (response) {
			console.log(response);
			react.setState({
				loading: false,
				profile: response.data.profile,
			})
		}).catch(function (error) {
			console.log(error);
		});

	}

	getProfileType(type) {

		if (type === 'fun') {
			return "FUN";
		}

		if (type === 'flirt') {
			return "FLIRT";
		}

		if (type === 'friend') {
			return "FRIEND";
		}

	}

	openLightbox(photo) {
		this.setState({
			lightbox_open: true,
			lightbox_link: get_image_url(photo.path, 500, 500)
		})
	}

	closeLightbox(id) {
		this.setState({
			lightbox_open: false,
			lightbox_link: null,
		})
	}

	checkLightbox() {

		if (this.state.lightbox_open) {

			return (
				<Lightbox
					mainSrc={this.state.lightbox_link}
					onCloseRequest={() => this.closeLightbox()}
				/>
			)

		}

	}

	changeStatus(photoId, status) {

		apiPhotoChangeStatus({
			token: this.state.token,
			photoId,
			status
		})
		if (status === 2) {
			this.setState({
				profile: {
					...this.state.profile,
					photos: this.state.profile.photos.filter(photo => photo.id !== photoId)
				}
			})
		} else {
			this.setState({
				profile: {
					...this.state.profile,
					photos: this.state.profile.photos.map(photo => {
						if (photo.id === photoId) {
							return {
								...photo,
								status
							}
						}
						return photo
					})
				}
			})
		}

	}

	changeProfileStatus(status) {

		apiProfileChangeStatus({
			token: this.state.token,
			profileId: this.state.profile.id,
			status
		})
		this.setState({
			profile: {
				...this.state.profile,
				adult_status: status
			}
		})

	}


	render() {

		let react = this;

		if (this.state.open) {

			let content = (
				<div className={"modal-spinner"}>
					<div className="v2-spinner" />
				</div>
			);

			if (!this.state.loading && this.state.profile !== null) {

				let profile = this.state.profile;

				console.log(profile);

				const photosToReview = [];
				if (profile.type === 'fun') {
					photosToReview.push(
						...profile.photos.filter(ph => ph.adult && ph.status === 1)
					)
				}
				if (profile.type === 'flirt') {
					photosToReview.push(
						...profile.photos.filter(ph => ph.type === 'private' && ph.adult && ph.status === 1)
					)
				}

				content = (
					<Fragment>
						<div className="modal-top">
							{this.checkLightbox()}
							<span className="modal-title">Profile information</span>
							<button className="modal-close" onClick={this.props.onClose.bind(this)}>×</button>
						</div>
						<div className="modal-content">
							<div className="row">
								<div className="modal-avatar-edit">
									<div className="avatar">
										{profile.adult === 1 && (
											<span className="avatar-adult-label">Adult</span>
										)}
										<img src={get_image_url(profile.avatar, 100, 100)} className="avatar-img" />
										<div className="avatar-bottom">
											{profile.adult_status === 1 && profile.adult === 1 && (
												<>
													<button className="modal-img-decline-button" onClick={this.changeProfileStatus.bind(this, 2)}>Decline</button>
													<button className="modal-img-accept-button" onClick={this.changeProfileStatus.bind(this, 3)}>Accept</button>
												</>
											)}
											{profile.adult_status === 3 && profile.adult === 1 && (
												<button className="modal-img-full-decline-button" onClick={this.changeProfileStatus.bind(this, 2)}>Decline</button>
											)}
											{profile.adult_status === 2 && profile.adult === 1 && (
												<>
													<button className="modal-img-full-accept-button" onClick={this.changeProfileStatus.bind(this, 3)}>Accept</button>
												</>
											)}
										</div>
									</div>
								</div>
								<div className="modal-description row">
									<span className="modal-description-title">Personal Information</span>
									<div className="modal-description-row row">
										<div className="modal-description-col">
											<span className="modal-description-label">Nickname</span>
											<span className="modal-description-value">{profile.name}</span>
										</div>
										<div className="modal-description-col">
											<span className="modal-description-label">Type</span>
											<span className="modal-description-value">{this.getProfileType(profile.type)}</span>
										</div>
									</div>
									<div className="modal-description-row row">
										<div className="modal-description-col">
											<span className="modal-description-label">Last login</span>
											<span className="modal-description-value">{profile.user.timestamp !== null && moment.unix(profile.user.timestamp).format("MMMM Do YYYY, h:mm:ss a")}</span>
										</div>
										{profile.user.email !== null && (
											<div className="modal-description-col">
												<span className="modal-description-label">Email</span>
												<span className="modal-description-value">{profile.user.email}</span>
											</div>
										)}
										{profile.user.phone !== null && (
											<div className="modal-description-col">
												<span className="modal-description-label">Phone</span>
												<span className="modal-description-value">{profile.user.phone}</span>
											</div>
										)}
									</div>
								</div>
							</div>
							{true && (
								<>
									<span className="modal-description-title" style={{ marginTop: 30 }}>Photos to review</span>
									<div className="modal-description-row row">
										{
											photosToReview.map(function (photo) {
												return (
													<div className="modal-img">
														<div className="modal-img-content">
															{photo.adult === 1 && (
																<span className="modal-img-adult-label">Adult</span>
															)}
															<div className="modal-img-hover" onClick={react.openLightbox.bind(react, photo)}><i className="fa fa-eye" /></div>
															<img src={get_image_url(photo.path, 200, 300)} />
														</div>
														<div className="modal-img-bottom">
															{photo.status === 1 && (
																<>
																	<button className="modal-img-decline-button" onClick={react.changeStatus.bind(react, photo.id, 2)}>Decline</button>
																	<button className="modal-img-accept-button" onClick={react.changeStatus.bind(react, photo.id, 3)}>Accept</button>
																</>
															)}
															{photo.status === 2 && (
																<>
																	<button className="modal-img-full-accept-button" onClick={react.changeStatus.bind(react, photo.id, 3)}>Accept</button>
																</>
															)}
															{(photo.status === 2 || photo.status === 0 || photo.status === 3) && (
																<>
																	<button className="modal-img-full-decline-button" onClick={react.changeStatus.bind(react, photo.id, 2)}>Decline</button>
																</>
															)}
														</div>
													</div>
												)
											})
										}
									</div>
								</>
							)}
						</div>
					</Fragment>
				);
			}

			return (
				<Fragment>
					<div className="modal-fade" onClick={this.props.onClose.bind(this)} />
					<div className="modal">
						{content}
					</div>
				</Fragment>
			)

		}

		return <div />

	}

}
