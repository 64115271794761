import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import axios from "axios";
import {laravel_api} from "../variables";
import Pusher from 'pusher-js';
import socketService from "../actions/socketService";
import {get_image_url} from "../utils";

class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profile_id: props.profile_id,
            loading: true,
            chat: null,
            messages: [],
            answer: '',
            src_profile: null,
            target_profile: null,
            send_loading: false,
        }
        this.socketService = socketService;
    }

    componentWillMount() {
        this.loadData();
    }

    componentDidMount() {
        this.connect();
    }

    componentWillUnmount() {
        this.disconnect();
    }

    connect() {
        let react = this;
        console.log('CONNECT');

        react.socketService.onReady(1182);
        react.socketService.getChannel().bind('message_event', function () {
            react.loadMessages();
        });
    }

    disconnect() {
        this.socketService.getChannel().unbind('message_event');
    }

    loadData() {

        let react = this;
        react.setState({
            loading: true,
        })

        axios.get(laravel_api + '/admin/support/load_chat?token=' + react.props.token + "&profile_id=" + react.state.profile_id).then(function (response) {
            console.log(response);
            react.setState({
                loading: false,
                chat: response.data.chat,
                src_profile: response.data.src_profile,
                target_profile: response.data.target_profile,
                messages: response.data.messages,
            })
        }).catch(function (error) {
            console.log(error);
        });

    }

    loadMessages() {

        let react = this;

        axios.get(laravel_api + '/admin/support/load_messages?token=' + react.props.token + "&chat_id=" + react.state.chat.id).then(function (response) {
            console.log(response);
            react.setState({
                loading: false,
                messages: response.data.messages,
            })
        }).catch(function (error) {
            console.log(error);
        });

    }

    checkSubTitle() {

        if (this.state.target_profile.user !== null) {
            if (this.state.target_profile.user.email !== null) {
                return <span className="chat-profile-sub-title">{this.state.target_profile.user.email}</span>
            }
            if (this.state.target_profile.user.phone !== null) {
                return <span className="chat-profile-sub-title">{this.state.target_profile.user.phone}</span>
            }
        }

    }

    sendMessage() {

        let react = this;
        if (react.state.answer.length > 0) {

            react.setState({
                send_loading: true,
            })

            axios.post(laravel_api + '/admin/support/send_message', {
                token: react.props.token,
                text: react.state.answer,
                chat_id: react.state.chat.id
            }).then(function (response) {
                console.log(response);
                react.setState({
                    send_loading: false,
                    answer: ''
                }, () => {
                    react.loadMessages();
                })
            }).catch(function (error) {
                console.log(error);
            });


        }

    }

    render() {

        let react = this;
        let content = (
            <div className="chat-window-loader">
                <div className="v2-spinner"/>
            </div>
        )

        if (!react.state.loading) {
            content = (
                <Fragment>
                    <div className="chat-window-top">
                        <div className="chat-profile">
                            <img src={get_image_url(react.state.target_profile.avatar, 45, 45)} alt="" className="chat-profile-image"/>
                            <div className="chat-profile-right">
                                <span className="chat-profile-name">{react.state.target_profile.name}</span>
                                {react.checkSubTitle()}
                            </div>
                        </div>
                        <button className="chat-window-close" onClick={react.props.onClose.bind(react)}>×</button>
                    </div>
                    <div className="chat-window-content">
                        {
                            react.state.messages.map(function (message) {
                                return <ChatMessage message={message}/>
                            })
                        }
                    </div>
                    <div className="chat-window-bottom">
                        {!react.state.send_loading &&
                            <span className="chat-window-send-button" onClick={() => {
                                react.sendMessage();
                            }}>
                                <i className="fa fa-send"/>
                            </span>
                        }
                        <input type="text" value={react.state.answer} onChange={(event) => {
                            react.setState({
                                answer: event.target.value
                            })
                        }} className={"chat-window-input"} placeholder={"Write your message..."}/>
                    </div>
                </Fragment>
            )
        }


        return (
            <div className="chat-window">
                {content}
            </div>
        )
    }

}

class ChatMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: props.message
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            message: nextProps.message
        })
    }

    getContent(textClassName) {

        let react = this;
        let message = react.state.message;

        if (message.image !== null) {
            return (
                <img src={'http://application.muslapp.com' + message.image} className="chat-element-message-image"/>
            )
        }

        return (
            <span className={textClassName}>{message.text}</span>
        )

    }

    render() {

        let react = this;
        let message = react.state.message;

        if (message.profile_id === 1206 || message.profile_id === 1207 || message.profile_id === 1208) {
            return (
                <div className="chat-element row">
                    <img src={get_image_url(message.profile.avatar, 30, 30)} alt="" className="chat-element-image"/>
                    <div className="chat-element-inner">
                        {react.getContent("chat-element-text")}
                    </div>
                </div>
            )
        }

        return (
            <div className="chat-element-2 row">
                <img src={get_image_url(message.profile.avatar, 30, 30)} alt="" className="chat-element-2-image"/>
                <div className="chat-element-2-inner">
                    {react.getContent("chat-element-2-text")}
                </div>
            </div>
        )


    }

}

export default connect(
    state => ({
        auth: state.user.auth,
        token: state.user.token,
    }),
    dispatch => ({})
)(Chat);

