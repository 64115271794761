import Pusher from 'pusher-js';

class SocketService {
  constructor() {
    this.pusher = null;
    this.channel = null;
    this.user_id = null;
  }

  onReady(user_id) {
    if (this.pusher === null && this.channel === null) {
      this.connect(user_id);
    } else {
      if (user_id !== this.user_id) {
        console.log('DISCONNECT', this.user_id);
        this.user_id = null;
        this.channel.unsubscribe();
        this.connect(user_id);
      }
    }
  }

  connect(user_id) {
    this.pusher = new Pusher('a99bc80f5d8843dddf67', {
      cluster: 'mt1',
    });
    this.channel = this.pusher.subscribe('user_channel_' + user_id);
    this.user_id = user_id;
    console.log('CONNECT', user_id);
  }

  getChannel() {
    return this.channel;
  }
}

const socketService = new SocketService();

export default socketService;
