import React, {Component, Fragment} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {connect} from 'react-redux';
import {Redirect} from "react-router";
import axios from "axios";
import {laravel_api} from "../variables";
import moment from "moment";
import Chat from "../components/Chat";
import {get_image_url} from "../utils";

class Messages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            chats: [],
            new_chats: [],
            selected_profile_id: null,
            hey: props.hey
        }
    }

    componentWillMount() {
        this.loadData();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            hey: nextProps.hey
        }, () => {
            this.loadData();
        })
    }

    loadData() {

        let react = this;
        react.setState({
            loading: true,
        });

        axios.get(laravel_api + '/admin/support_load_chats?token=' + react.props.token + '&hey=' + react.state.hey).then(function (response) {
            console.log(response);
            react.setState({
                loading: false,
                new_chats: react.getChats(response.data.chats, 'new'),
                chats: react.getChats(response.data.chats, 'all')
            })
        }).catch(function (error) {
            console.log(error);
        });

    }

    getChats(chats, type) {

        let result = [];

        chats.forEach(function (chat) {

            let message = chat.messages[0];

            if (type === "new") {
                if (message.profile_id !== 1206 && message.profile_id !== 1207 && message.profile_id !== 1208) {
                    result.push(chat);
                }
            }

            if (type === "all") {
                if (message.profile_id !== 1206 && message.profile_id !== 1207 && message.profile_id !== 1208) {
                } else {
                    result.push(chat);
                }
            }


        })

        return result;

    }

    getChatWindow() {

        if (this.state.selected_profile_id !== null) {
            return (
                <Chat
                    profile_id={this.state.selected_profile_id}
                    onClose={() => {
                        this.setState({
                            selected_profile_id: null
                        })
                    }}
                />
            )
        }

    }

    openChatModal(profile_id) {
        this.setState({
            selected_profile_id: profile_id
        })
    }

    render() {

        let react = this;

        if (!this.props.auth) {
            return (<Redirect to={"/login"}/>)
        }

        let content = (<div className="v2-spinner-inner"><div className="v2-spinner"/></div>);

        if (!this.state.loading) {
            content = (
                <div className="content">
                    {this.getChatWindow()}
                    <div className="content-inner">
                        <div className="page-top row">
                            <h1 className="page-title">Unanswered messages</h1>
                        </div>
                        <div className="messages row">
                            {
                                react.state.new_chats.map(function (chat) {
                                    return (
                                        <Message
                                            chat={chat}
                                            onOpenChatModal={react.openChatModal.bind(react)}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div className="page-top row">
                            <h1 className="page-title">Messages</h1>
                        </div>
                        <div className="messages row">
                            {
                                react.state.chats.map(function (chat) {
                                    return (
                                        <Message
                                            chat={chat}
                                            onOpenChatModal={react.openChatModal.bind(react)}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <Fragment>
                <Header menu={true}/>
                {content}
                <Footer/>
            </Fragment>
        )
    }

}


class Message extends Component {

    render() {

        let react = this;
        let chat = this.props.chat;
        let message = chat.messages[0];

        return (
            <div className="message row">
                <img src={get_image_url(chat.target_profile.avatar, 100, 100)} className="message-profile-avatar"/>
                <div className="message-right">
                    <div className="message-right-top row">
                        <span className="message-user-name">{chat.target_profile.name}</span>
                        <span className="message-user-sub-title">{chat.target_profile.user.email} {chat.target_profile.user.phone}</span>
                    </div>
                    <div className="message-right-message">
                        <span className="message-right-message-text">{message.text}</span>
                    </div>
                    <div className="message-right-bottom">
                        <span className="message-right-bottom-username">{message.profile.name}</span>
                        <span className="message-right-bottom-date">{moment(message.created_at).format("MM/DD, h:mm:ss a")}</span>
                    </div>
                </div>
                <button className={"message-open-button"} onClick={react.props.onOpenChatModal.bind(react, chat.target_profile_id)}>
                    {(message.profile_id !== 1206 && message.profile_id !== 1207 && message.profile_id !== 1208) &&
                        <span className="message-open-button-circle"/>
                    }
                    <i className={"fa fa-send"}/>
                </button>
            </div>
        )

    }

}


export default connect(
    state => ({
        auth: state.user.auth,
        token: state.user.token,
        hey: state.user.hey,
    })
)(Messages);
