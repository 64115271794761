import React, {Component} from 'react';

export default class Footer extends Component {

    render() {
        return (
            <div className="footer">
                <span className="footer-copyright">© 2023 MUSL V2.1</span>
            </div>
        )
    }

}
