const initialState = {
    auth: false,
    token: null,
    hey: 0
};

export default function user(state = initialState, action) {

    switch (action.type) {
        case 'LOGOUT':
            return {
                ...state,
                auth: false,
                token: null,
            };
        case 'SET_USER':
            return {
                ...state,
                auth: true,
                token: action.token,
            };
        case 'SET_HEY':
            return {
                ...state,
                hey: action.hey,
            };
        default:
            return state;
    }
}
