import React, {Component, Fragment} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {connect} from 'react-redux';
import {Redirect} from "react-router";
import axios from "axios";
import {laravel_api} from "../variables";

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            friend_num: 0,
            friend_num_today: 0,
            flirt_num: 0,
            flirt_num_today: 0,
            fun_num: 0,
            fun_num_today: 0,
            hey: props.hey
        }
    }

    componentWillMount() {
        this.loadData();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            hey: nextProps.hey
        }, () => {
            this.loadData();
        })
    }

    loadData() {

        let react = this;
        react.setState({
            loading: true,
        });

        axios.get(laravel_api + '/admin/statistic?token=' + react.props.token + '&hey=' + react.state.hey).then(function (response) {
            console.log(response);
            react.setState({
                loading: false,
                friend_num: response.data.friend_num,
                friend_num_today: response.data.friend_num_today,
                flirt_num: response.data.flirt_num,
                flirt_num_today: response.data.flirt_num_today,
                fun_num: response.data.fun_num,
                fun_num_today: response.data.fun_num_today,
            })
        }).catch(function (error) {
            console.log(error);
        });

    }

    render() {

        if (!this.props.auth) {
            return (<Redirect to={"/login"}/>)
        }

        let content = (<div className="v2-spinner-inner"><div className="v2-spinner"/></div>);

        if (!this.state.loading) {
            content = (
                <div className="content">
                    <div className="content-inner">
                        <div className="page-top row">
                            <h1 className="page-title">Dashboard</h1>
                        </div>
                        <div className="dashboard-blocks row">
                            <div className="dashboard-block">
                                <div className="dashboard-block-left">
                                    <span className="dashboard-block-title-1">Friend profiles</span>
                                    <span className="dashboard-block-title-2">{this.state.friend_num}/{this.state.friend_num_today}</span>
                                </div>
                                <div className="dashboard-block-right">
                                    <img src="images/icon-friend@3x.png" alt=""/>
                                </div>
                            </div>
                            <div className="dashboard-block">
                                <div className="dashboard-block-left">
                                    <span className="dashboard-block-title-1">Flirt profiles</span>
                                    <span className="dashboard-block-title-2">{this.state.flirt_num}/{this.state.flirt_num_today}</span>
                                </div>
                                <div className="dashboard-block-right">
                                    <img src="images/icon-flirt@3x.png" alt=""/>
                                </div>
                            </div>
                            <div className="dashboard-block">
                                <div className="dashboard-block-left">
                                    <span className="dashboard-block-title-1">Fun profiles</span>
                                    <span className="dashboard-block-title-2">{this.state.fun_num}/{this.state.fun_num_today}</span>
                                </div>
                                <div className="dashboard-block-right">
                                    <img src="images/icon-fun@3x.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <Fragment>
                <Header menu={true}/>
                {content}
                <Footer/>
            </Fragment>
        )
    }

}


export default connect(
    state => ({
        auth: state.user.auth,
        token: state.user.token,
        hey: state.user.hey
    })
)(Dashboard);
