import React, {Component, Fragment} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {connect} from 'react-redux';
import {Redirect} from "react-router";
import axios from "axios";
import {laravel_api} from "../variables";
import moment from "moment";
import {DelayInput} from 'react-delay-input';
import ProfileModal from "../modals/ProfileModal";
import Chat from "../components/Chat";
import PopupCreateModal from "../modals/PopupCreateModal";
import PopupEditModal from "../modals/PopupEditModal";

class Popups extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            popups: [],
            modal_open: false,
            modal_edit_open: false,
            modal_edit_id: null,
        }
    }

    componentWillMount() {
        this.loadPopups();
    }

    loadPopups() {

        let react = this;
        react.setState({
            loading: true
        })


        axios.get(laravel_api + '/admin/popups.get?token=' + react.props.token).then(function (response) {
            console.log(response);
            react.setState({
                loading: false,
                popups: response.data.popups
            })
        }).catch(function (error) {
            console.log(error);
        });

    }

    openModal() {
        this.setState({
            modal_open: true,
        })
    }

    openEditModal(id) {
        this.setState({
            modal_edit_open: true,
            modal_edit_id: id
        })
    }

    closeModal() {
        this.setState({
            modal_open: false,
        })
    }

    closeEditModal() {
        this.setState({
            modal_edit_open: false,
            modal_edit_id: null,
        })
    }

    delete(id) {

        if (window.confirm("Are you sure you want to delete this popup?")) {

            let react = this;
            react.setState({
                loading: true,
            });

            axios.post(laravel_api + '/admin/popup.delete', {
                token: react.props.token,
                id
            }).then(function () {
               react.loadPopups();
            }).catch(function (error) {
                console.log(error);
            });

        }

    }

    render() {

        let react = this;
        if (!this.props.auth) {
            return (<Redirect to={"/login"}/>)
        }

        let content = (<div className="v2-spinner-inner"><div className="v2-spinner"/></div>);

        if (!this.state.loading) {
            content = (
                <div className="content-area">
                    <div className="bordered-table">
                        <table>
                            <tr>
                                <th style={{width: '200px'}}>Title</th>
                                <th>Message</th>
                                <th style={{width: '120px'}}>Type</th>
                                <th style={{width: '120px'}}>Active</th>
                                <th style={{width: '120px'}}>Created at</th>
                                <th className="padding-no"  style={{width: '40px'}}/>
                                <th className="padding-no"  style={{width: '40px'}}/>
                            </tr>
                            {
                                react.state.popups.map(function (popup) {
                                    return (
                                        <tr>
                                            <td>{popup.title}</td>
                                            <td>{popup.message}</td>
                                            <td>{popup.type == 'once' ? 'Once' : 'All time'}</td>
                                            <td>{popup.active == '1' ? 'Yes' : 'No'}</td>
                                            <td>{moment(popup.created_at).format('YYYY-MM-DD')}</td>
                                            <td className="padding-no" onClick={react.openEditModal.bind(react, popup.id)}>
                                                <button className="edit-button"><i className="fa fa-pencil"/></button>
                                            </td>
                                            <td className="padding-no" onClick={react.delete.bind(react, popup.id)}>
                                                <button className="delete-button"><i className="fa fa-trash"/></button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </div>
                </div>
            )
        }

        return (
            <Fragment>
                <Header menu={true}/>
                <PopupCreateModal
                    open={react.state.modal_open}
                    onClose={react.closeModal.bind(react)}
                    onUpdate={() => {
                        react.setState({
                            modal_open: false
                        }, () => {
                            react.loadPopups();
                        })
                    }}
                />
                <PopupEditModal
                    open={react.state.modal_edit_open}
                    id={react.state.modal_edit_id}
                    onClose={react.closeEditModal.bind(react)}
                    onUpdate={() => {
                        react.setState({
                            modal_edit_open: false,
                            modal_edit_id: null,
                        }, () => {
                            react.loadPopups();
                        })
                    }}
                />
                <div className="content">
                    <div className="content-inner">
                        <div className="page-top row">
                            <h1 className="page-title">Popups</h1>
                            <button className="page-top-create-button" onClick={react.openModal.bind(react)}>
                                <i className="fa fa-plus-square"/>
                                Create popup
                            </button>
                        </div>
                        {content}
                    </div>
                </div>
                <Footer/>
            </Fragment>
        )
    }

}


export default connect(
    state => ({
        auth: state.user.auth,
        token: state.user.token,
    })
)(Popups);
