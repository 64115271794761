import React, {Component, Fragment} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {setUser} from "../actions/userActions";
import axios from "axios";
import {laravel_api} from "../variables";

class Reset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: null,
            loading: false
        }
    }

    reset() {

        let react = this;

        if (react.state.email.length === 0) {
            react.setState({
                error: "Invalid email",
            });
            return;
        }

        react.setState({
            loading: true
        });

        axios.get(laravel_api + '/admin/reset?email=' + react.state.email).then(function (response) {
            if (response.data.status === "ok") {
                alert("Password reset email has been sent");
                window.location.href = "/";
            } else {
                react.setState({
                    error: response.data.error,
                    loading: false,
                });
            }
        }).catch(function (error) {
            react.setState({
                loading: false,
            }, () => {
                alert("Service error");
            })
        });

    }

    render() {

        if (this.props.auth) {
            return (<Redirect to={"/"}/>)
        }

        if (this.state.loading) {
            return <div className="v2-spinner-inner"><div className="v2-spinner"/></div>
        }

        return (
            <Fragment>
                <Header menu={false}/>
                <div className="auth-page row">
                    <div className="container">
                        <div className="auth-page-left"/>
                        <div className="auth-page-right">
                            <span className="auth-page-title">Forgot password?</span>
                            <span className="auth-page-sub-title">Enter email address</span>
                            <div className="auth-page-form">
                                <div className="auth-page-form-element">
                                    <label>Email</label>
                                    <input value={this.state.email} onChange={(event) => (this.setState({email: event.target.value, error: null}))} type="text" placeholder=""/>
                                </div>
                                <span className="auth-page-error">{this.state.error}</span>
                                <button className="auth-page-button" onClick={this.reset.bind(this)}>Confirm</button>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer/>
            </Fragment>
        )
    }

}

export default connect(
    state => ({
        auth: state.user.auth,
    }),
    dispatch => ({

    })
)(Reset);

