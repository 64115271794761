import React, {Component, Fragment} from 'react';
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {logout, setHey} from "../actions/userActions";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submenu_open: false,
            hey: props.hey
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            hey: nextProps.hey
        })
    }

    changeHey(hey) {
        this.props.onSetHey(hey);
    }

    changeSubmenuOpen() {
        this.setState({
            submenu_open: !this.state.submenu_open,
        })
    }

    checkNavigation() {
        if (this.props.menu) {
            return (
                <Fragment>
                    <ul className="header-navigation">
                        <li><NavLink to="/" activeClassName="active">Dashboard</NavLink></li>
                        <li><NavLink to="/profiles">Profiles</NavLink></li>
                        <li><NavLink to="/popups">Popups</NavLink></li>
                        <li><NavLink to="/messages">Messages</NavLink></li>
                    </ul>
                    {this.state.hey === 0 && (
                        <button className="header-switcher" onClick={() => {
                            this.props.onSetHey(1);
                        }}>Switch to HeyHeyHey</button>
                    )}
                    {this.state.hey === 1 && (
                        <button className="header-switcher" onClick={() => {
                            this.props.onSetHey(0);
                        }}>Switch to MUSL</button>
                    )}
                </Fragment>
            )
        }
    }

    logout() {
        this.props.onLogout();
    }

    checkUser() {
        if (this.props.auth) {
            return (
                <div className="header-user">
                    <div className="header-user-avatar" onClick={this.changeSubmenuOpen.bind(this)}/>
                    {this.getSubmenu()}
                </div>
            )
        }
    }

    getSubmenu() {
        if (this.state.submenu_open) {
            return (
                <div className="header-user-submenu">
                    <div className="header-user-large-avatar"/>
                    <span className="header-user-title">Jason Kelley</span>
                    <span className="header-user-sub-title">Administrator</span>
                    <ul className="header-user-links">
                        <li><span onClick={this.logout.bind(this)}><i className="fa fa-sign-out"/>Logout</span></li>
                    </ul>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="header">
                <NavLink to="/" className="header-logo">
                    {this.state.hey === 0 && (
                        <img src="/images/logo.png" alt=""/>
                    )}
                    {this.state.hey === 1 && (
                        <img src="/images/logo_hey.png" alt=""/>
                    )}
                </NavLink>
                {this.checkNavigation()}
                {this.checkUser()}
            </div>
        )
    }

}

export default connect(
    state => ({
        auth: state.user.auth,
        hey: state.user.hey
    }),
    dispatch => ({
        onLogout: () => {
            dispatch(logout());
        },
        onSetHey: (hey) => {
            dispatch(setHey(hey));
        }
    })
)(Header);

