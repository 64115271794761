import cookie from 'react-cookies';

export const setUser = (token) => {
    return dispatch => {
        cookie.save("token", token, {path: '/'});
        dispatch({
            type: "SET_USER",
            token: token,
        });
    }
};


export const logout = () => {
    cookie.remove("token", {path: '/'});
    return dispatch => {
        dispatch({
            type: "LOGOUT"
        });
    }
};


export const setHey = (hey) => {
    return dispatch => {
        cookie.save("hey", hey.toString(), {path: '/'});
        dispatch({
            type: "SET_HEY",
            hey: hey,
        });
    }
};
