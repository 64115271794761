import React, {Component, Fragment} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {setUser} from "../actions/userActions";
import axios from "axios";
import {laravel_api} from "../variables";

class SignIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: '',
            error: null,
            loading: false
        }
    }

    auth() {

        let react = this;

        if (react.state.login.length === 0 && react.state.password.length === 0) {
            react.setState({
                error: "Invalid login or password",
            });
            return;
        }

        react.setState({
            loading: true
        });

        axios.get(laravel_api + '/admin/auth_2?email=' + react.state.login + '&password=' + react.state.password).then(function (response) {
            if (response.data.status === "ok") {
                react.props.onSetUser(response.data.access_token);
            } else {
                react.setState({
                    error: response.data.error,
                    loading: false,
                });
            }
        }).catch(function (error) {
            react.setState({
                loading: false,
            }, () => {
                alert("Service error");
            })
        });

    }

    render() {

        if (this.props.auth) {
            return (<Redirect to={"/"}/>)
        }

        if (this.state.loading) {
            return <div className="v2-spinner-inner"><div className="v2-spinner"/></div>
        }

        return (
            <Fragment>
                <Header menu={false}/>
                <div className="auth-page row">
                    <div className="container">
                        <div className="auth-page-left"/>
                        <div className="auth-page-right">
                            <span className="auth-page-title">Sign In</span>
                            <span className="auth-page-sub-title">Welcome back! Please log in to continue.</span>
                            <div className="auth-page-form">
                                <div className="auth-page-form-element">
                                    <label>Login</label>
                                    <input value={this.state.login} onChange={(event) => (this.setState({login: event.target.value, error: null}))} type="text" placeholder=""/>
                                </div>
                                <div className="auth-page-form-element">
                                    <label>Password</label>
                                    <input value={this.state.password} onChange={(event) => (this.setState({password: event.target.value, error: null}))} type="password" placeholder=""/>
                                </div>
                                <span className="auth-page-error">{this.state.error}</span>
                                <a href="/reset" className={"reset-link"}>Forgot password?</a>
                                <button className="auth-page-button" onClick={this.auth.bind(this)}>Sign In</button>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer/>
            </Fragment>
        )
    }

}

export default connect(
    state => ({
        auth: state.user.auth,
    }),
    dispatch => ({
        onSetUser: (token) => {
            dispatch(setUser(token));
        }
    })
)(SignIn);

