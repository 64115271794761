import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory as createHistory } from 'history'
import {ConnectedRouter, routerMiddleware} from 'react-router-redux';
import {createLogger} from 'redux-logger'
import {Switch} from "react-router-dom";
import reducer from './reducers';
import {Route} from "react-router";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import cookie from "react-cookies";
import axios from "axios";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
import {laravel_api} from "./variables";
import Profiles from "./pages/Profiles";
import Reset from "./pages/Reset";
import Messages from "./pages/Messages";
import Popups from "./pages/Popups";

const history = createHistory()
const middleware = routerMiddleware(history);

const logger = createLogger({});
const store = createStore(reducer, applyMiddleware(middleware, thunk, logger));

export default class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentWillMount() {

        let react = this;
        let token = cookie.load('token', {path: '/'});

        if (token !== undefined) {

            axios.get(laravel_api + '/admin/session?token=' + token).then(function (response) {
                if (response.data.status === 'ok') {
                    cookie.save("token", token, {path: '/'});
                    store.dispatch({
                        type: "SET_USER",
                        token: token,
                    });
                } else {
                  cookie.remove("token", {path: '/'});
                }
                react.setState({
                    loading: false
                })
            }).catch(function () {
                cookie.remove("token", {path: '/'});
                react.setState({
                    loading: false
                })
            });

        } else {
            cookie.remove("token", {path: '/'});
            react.setState({
                loading: false
            })
        }

    }

    render() {

        if (this.state.loading) {
            return <div className="v2-spinner-inner"><div className="v2-spinner"/></div>
        }

        return (
            <>
                <NotificationContainer/>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <Switch>
                            <Route exact path="/" component={Dashboard}/>
                            <Route exact path="/login" component={SignIn}/>
                            <Route exact path="/messages" component={Messages}/>
                            <Route exact path="/profiles" component={Profiles}/>
                            <Route exact path="/popups" component={Popups}/>
                            <Route exact path="/reset" component={Reset}/>
                        </Switch>
                    </ConnectedRouter>
                </Provider>
            </>
        )

    }


}
